
import { Vue, Options } from "vue-property-decorator";
import LoginModel from "./LoginModel";
@Options({
  name: "authotization",
})
export default class Logger extends Vue {
  loginModel: LoginModel = new LoginModel();
  autofocusPasswordInput = false;

  // Вход в аакаунт
  onLogin() {
    this.$api.UserService.UserLoginEvent.once((res) => {
      if (res.IsSuccess) {
        this.$router.push({ path: "/" });
      } else {
        this.$notification.error(res.ErrorMessage, "Ошибка");
      }
    });
    this.$api.UserService.UserLogin(this.loginModel);
  }

  onPasswordFocus() {
    this.autofocusPasswordInput = true;
  }
}
